const content = {
  title: 'Weitere Absicherungsmöglichkeiten',
  headvisualHeadline: 'Weitere Absicherungs&shy;möglichkeiten',
  sectionHead: {
    headline: 'Weil ihr Mitarbeiter es Ihnen Wert ist',
    paragraph:
      'Neben einer betrieblichen Altersvorsorge, dem betrieblichen Einkommensschutz und der betrieblichen Krankenversicherung gibt es noch weitere Möglichkeiten Ihren Mitarbeiter abzusichern.'
  },
  contentBox: {
    headline: 'Das Wertkonto',
    paragraphs: [
      'Bei Wertkonten handelt es sich um Arbeitszeitkonten, die Mitarbeiter dabei unterstützen, während ihrer aktiven Dienstzeit eine individuelle Lebensarbeitszeit zu gestalten. Zu diesem Zweck können Arbeitnehmer einzelne Bestandteile ihres Entgelts (z. B. Überstunden, Urlaub oder Sonderzahlungen) als eine Art Wertguthaben auf einer Art Sparbuch ansammeln. Dieses Guthaben kann der Mitarbeiter dann beispielsweise zur Finanzierung seines vorgezogenen Vorruhestands verwenden. Der Arbeitgeber entnimmt hierzu das laufende Gehalt aus dem Wertguthaben.',
      'Der Gesetzgeber unterstützt den Arbeitnehmer bei der Ansammlung von Wertguthaben indem er alle  Einzahlungen in das Wertguthaben zunächst steuer- und sozialabgabenfrei stellt. Erst während der Auszahlung in der Freistellung werden Steuern und Abgaben fällig.',
      '<strong>Hinweis:</strong> Das Wertkonto wird derzeit nicht von der NÜRNBERGER Versicherung angeboten.'
    ]
  }
};

export default content;
