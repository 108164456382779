import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  Paragraphs
} from 'src/components';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/weitere-absicherungsmoeglichkeiten';
import subpageNavigations from 'src/data/subpage-navigations';

const WeitereAbsicherungsmoeglichkeitenPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: "75"
    }}
    subpageNavItems={subpageNavigations.absicherungFuerMitarbeiter}
    activeNavItem={4}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.sectionHead.headline} centered>
        <p>{content.sectionHead.paragraph}</p>
      </SectionHead>
      <HalvedContent swapped>
        <BackgroundImage
          fluid={data.mimiThian.childImageSharp.fluid}
          className={"bg-75"}
        />
        <ContentBox
          headline={content.contentBox.headline}
          background="gray-blue"
          align="right"
          wider
        >
          <Paragraphs items={content.contentBox.paragraphs} innerHTML />
        </ContentBox>
      </HalvedContent>
    </section>
  </MainLayout>
);

export default WeitereAbsicherungsmoeglichkeitenPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-typing-on-tablet-wide.jpg" }
    ) {
      ...image
    }
    mimiThian: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "mimi-thian.jpg" }
    ) {
      ...image
    }
  }
`;
